import C from './constants';

const isOpen = state => state[C.REDUCER_NAME][C.PROPS.IS_OPEN];

const status = state => state[C.REDUCER_NAME][C.PROPS.STATUS];

const getFocusedPoint = state => state[C.REDUCER_NAME][C.PROPS.FOCUSED_POINT];

const getFocusedWkt = state => state[C.REDUCER_NAME][C.PROPS.FOCUSED_WKT];

const getFeatures = state => state[C.REDUCER_NAME][C.PROPS.FEATURES];

const getShiptypes = state => state[C.REDUCER_NAME][C.PROPS.SHIPTYPES];

export default {
  isOpen,
  status,
  getFocusedPoint,
  getFocusedWkt,
  getFeatures,
  getShiptypes,
};
