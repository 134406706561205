import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@lib/xhr';

const getVessels = createAsyncThunk(
  'vessel/getVessels',
  async ({ center, boundingBox, fromTime }) => {
    const response = await get(
      `/statesbbox/${center}/${boundingBox}/${fromTime}`
    );
    return response.data.data;
  }
);

export default { getVessels };
