import { createSlice } from '@reduxjs/toolkit';
import C from './constants';

const initialState = {
  [C.PROPS.IS_PLAYER_ON]: false,
  [C.PROPS.IS_PLAYING]: false,
  [C.PROPS.SPEED]: C.DEFAULT_TRACK_SPEED,
  [C.PROPS.RESET_TRACK_PROGRESS]: false,
  [C.PROPS.DATE_RANGE]: {
    start: C.DEFAULT_START_DATE,
    end: C.DEFAULT_END_DATE,
  },
  [C.PROPS.DATA]: [],
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    change: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    changePastTrackdata: (state, action) => {
      state[C.PROPS.DATA] = [
        ...state[C.PROPS.DATA].filter(
          item => item.vesselId !== action.payload.vesselId
        ),
        action.payload,
      ];
    },
    clearPastTrackData: (state, action) => {
      state[C.PROPS.DATA] = [];
    },
  },
});

export default slice;
