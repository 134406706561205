const mode = 'dark';

const primary = {
  main: '#008DD2',
  contrastText: '#ffffff',
};

export default {
  mode,
  primary,
};
