import U from './utils';

const base = {
  fontFamily:
    'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
};

const variants = theme => {
  const fontFamily = base.fontFamily;
  const fontSize = size => theme.typography.pxToRem(size);
  return {
    h1: {
      fontFamily,
      fontSize: fontSize(48),
      lineHeight: 1.2,
      letterSpacing: 2,
      fontWeight: base.fontWeightBold,
    },
    h2: {
      fontFamily,
      fontSize: fontSize(40),
      lineHeight: 1.2,
      letterSpacing: 0,
      fontWeight: base.fontWeightBold,
    },
    h3: {
      fontFamily,
      fontSize: fontSize(32),
      lineHeight: 1.2,
      letterSpacing: 0,
      fontWeight: base.fontWeightBold,
    },
    h4: {
      fontFamily,
      fontSize: fontSize(24),
      lineHeight: U.round(36 / 24),
      letterSpacing: 0,
      fontWeight: base.fontWeightBold,
    },
    h5: {
      fontFamily,
      fontSize: fontSize(20),
      lineHeight: U.round(30 / 20),
      letterSpacing: 0,
      fontWeight: base.fontWeightBold,
    },
    h6: {
      fontFamily,
      fontSize: fontSize(18),
      lineHeight: U.round(28 / 18),
      letterSpacing: 0,
      fontWeight: base.fontWeightBold,
    },
    subtitle1: {
      fontFamily,
      fontSize: fontSize(16),
      lineHeight: U.round(24 / 16),
      letterSpacing: 0,
      fontWeight: base.fontWeightMedium,
    },
    subtitle2: {
      fontFamily,
      fontSize: fontSize(14),
      lineHeight: U.round(22 / 14),
      letterSpacing: 0,
      fontWeight: base.fontWeightMedium,
    },
    body1: {
      fontFamily,
      fontSize: fontSize(16),
      lineHeight: U.round(24 / 16),
      letterSpacing: 0,
      fontWeight: base.fontWeightRegular,
    },
    body2: {
      fontFamily,
      fontSize: fontSize(14),
      lineHeight: U.round(21 / 14),
      letterSpacing: 0,
      fontWeight: base.fontWeightRegular,
    },
    caption: {
      fontFamily,
      fontSize: fontSize(12),
      lineHeight: U.round(18 / 12),
      letterSpacing: 0,
      fontWeight: base.fontWeightRegular,
    },
    overline: {
      fontFamily,
      fontSize: fontSize(12),
      lineHeight: U.round(18 / 12),
      letterSpacing: 0,
      fontWeight: base.fontWeightBold,
    },
    button: {
      fontFamily,
      fontSize: fontSize(14),
      lineHeight: U.round(24 / 14),
      letterSpacing: 0,
      textTransform: 'none',
      fontWeight: base.fontWeightMedium,
    },
  };
};

const typography = theme => ({
  ...base,
  ...variants(theme),
});

export default typography;
