import { createSlice } from '@reduxjs/toolkit';
import C from './constants';
import thunk from './thunk';

const initialState = {
  [C.PROPS.IS_ARRIVALS_OPEN]: false,
  [C.PROPS.IS_DESTINATION_ON]: false,
  [C.PROPS.ARRIVALS]: [],
  [C.PROPS.FOCUSED_PORT]: null,
  [C.PROPS.FOCUSED_VESSEL_ARRIVAL]: null,
  [C.PROPS.TYPE]: 'patterns_of_life',
  [C.PROPS.STATUS_DESTINATION_POLYGON]: null,
  [C.PROPS.STATUS_DESTINATION_ROUTE]: null
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    change: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: builder => {
    builder.addCase(thunk.getExpectedArrivals.fulfilled, (state, action) => {
      state[C.PROPS.ARRIVALS] = action.payload;
    });
  },
});

export default slice;
