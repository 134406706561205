import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { HashRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import AuthProvider from './context/auth';
import SnackbarProvider from './components/snackbar/SnackbarProvider';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import store from './store';
import App from './App';

const queryClient = new QueryClient();
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <HashRouter>
            <AuthProvider>
              <CssBaseline />
              <App />
            </AuthProvider>
          </HashRouter>
        </ReduxProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
