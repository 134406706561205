const REDUCER_NAME = 'nextPosition';
const PROPS = {
  INTERVAL: 'interval',
  MODEL: 'model',
};

export default {
  REDUCER_NAME,
  PROPS,
};
