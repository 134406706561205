import { createSlice } from '@reduxjs/toolkit';
import C from './constants';

const initialState = {
  [C.PROPS.INTERVAL]: null,
  [C.PROPS.MODEL]: 'kinematic_a',
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    change: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export default slice;
