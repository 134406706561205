import C from './constants';

const isArrivalsOpen = state => state[C.REDUCER_NAME][C.PROPS.IS_ARRIVALS_OPEN];

const isDestinationOn = state =>
  state[C.REDUCER_NAME][C.PROPS.IS_DESTINATION_ON];

const getArrivals = state => state[C.REDUCER_NAME][C.PROPS.ARRIVALS];

const getFocusedPort = state => state[C.REDUCER_NAME][[C.PROPS.FOCUSED_PORT]];

const getFocusedVesselArrival = state =>
  state[C.REDUCER_NAME][[C.PROPS.FOCUSED_VESSEL_ARRIVAL]];

const getDestinationType = state => state[C.REDUCER_NAME][[C.PROPS.TYPE]];

export default {
  isArrivalsOpen,
  getArrivals,
  getFocusedPort,
  getFocusedVesselArrival,
  isDestinationOn,
  getDestinationType,
};
