import { alpha } from '@mui/material/styles';

const styleOverrides = {
  input: ({ theme }) => {
    return {
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 100px ${alpha(
          theme.palette.background.paper,
          0.85
        )} inset`,
        WebkitTextFillColor: '#fff',
        caretColor: '#fff',
      },
    };
  },
};

export default {
  styleOverrides,
};
