import slice from './slice';
import C from './constants';

const { change } = slice.actions;

const changeInterval = value => change({ key: C.PROPS.INTERVAL, value });
const changeModel = value => change({ key: C.PROPS.MODEL, value });

export default {
  changeInterval,
  changeModel,
};
