import slice from './slice';
import C from './constants';

const { change, changeFeature, changeShiptype, clearData } = slice.actions;

const changeIsOpen = value => change({ key: C.PROPS.IS_OPEN, value });

const changeStatus = value => change({ key: C.PROPS.STATUS, value });

const changeFocusedPoint = value =>
  change({ key: C.PROPS.FOCUSED_POINT, value });

const changeFocusedWkt = value => change({ key: C.PROPS.FOCUSED_WKT, value });

export default {
  changeIsOpen,
  changeStatus,
  changeFeature,
  changeShiptype,
  clearData,
  changeFocusedPoint,
  changeFocusedWkt,
};
