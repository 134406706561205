const REDUCER_NAME = 'settings';

const PROPS = {
  IS_OPEN: 'isOpen',
  SHOW_TRAFFIC_FLOW: 'showTrafficFlow',
  SHOW_VESSELS: 'showVessels',
};

export default {
  REDUCER_NAME,
  PROPS,
};
