import { createSlice } from '@reduxjs/toolkit';
import C from './constants';

const initialState = {
  [C.PROPS.LEGEND]: C.INIT_LEGEND,
  [C.PROPS.LOADING_SCREEN]: C.INIT_LOADING,
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    change: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    changeLoadingStatus: (state, action) => {
      state[C.PROPS.LOADING_SCREEN].isLoading = action.payload;
    },
    changeLoadingMessage: (state, action) => {
      state[C.PROPS.LOADING_SCREEN].message = action.payload;
    },
    changeIsLegendOpen: (state, action) => {
      state[C.PROPS.LEGEND].isOpen = action.payload;
    },
    changeLegendTitle: (state, action) => {
      state[C.PROPS.LEGEND].title = action.payload;
    },
    changeLegendColors: (state, action) => {
      state[C.PROPS.LEGEND].colors = action.payload;
    },
    changeLegendCategories: (state, action) => {
      state[C.PROPS.LEGEND].categories = action.payload;
    },
  },
});

export default slice;
