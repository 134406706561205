import { createSlice } from '@reduxjs/toolkit';
import C from './constants';

const initialState = {
  [C.PROPS.IS_OPEN]: false,
  [C.PROPS.MAP_EVENT_TYPES]: [],
  [C.PROPS.POL_EVENT_TYPES]: C.PATTERNS_EVENTS,
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    changeIsOpen: (state, action) => {
      state[C.PROPS.IS_OPEN] = action.payload;
    },
    setEventTypes: (state, action) => {
      state[action.payload.key] = action.payload.value.map(
        ({ id, is_enabled, type }) => ({
          id,
          is_enabled,
          event_type: type,
        })
      );
    },
    changeEventType: (state, action) => {
      state[action.payload.key] = state[action.payload.key].map(item =>
        item.id === action.payload.value.id
          ? {
              ...item,
              is_enabled: action.payload.value.is_enabled,
              event_type: action.payload.value.event_type,
            }
          : item
      );
    },
    changeEventTypes: (state, action) => {
      state[action.payload.key] = state[action.payload.key].map(item =>
        item.event_type === action.payload.value.type
          ? {
              ...item,
              is_enabled: action.payload.value.is_enabled,
            }
          : { ...item }
      );
    },
  },
});

export default slice;
