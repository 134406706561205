import { createSlice } from '@reduxjs/toolkit';
import C from './constants';

const initialState = {
  [C.PROPS.IS_OPEN]: false,
  [C.PROPS.VESSEL_FILTER_TYPES]: [
    { id: '0', is_enabled: true },
    { id: '1', is_enabled: true },
    { id: '2', is_enabled: true },
    { id: '3', is_enabled: true },
    { id: '4', is_enabled: true },
    { id: '5', is_enabled: true },
    { id: '6', is_enabled: true },
    { id: '7', is_enabled: true },
  ],
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    changeIsOpen: (state, action) => {
      state[C.PROPS.IS_OPEN] = action.payload;
    },
    changeVesselType: (state, action) => {
      state[C.PROPS.VESSEL_FILTER_TYPES] = state[
        C.PROPS.VESSEL_FILTER_TYPES
      ].map(item =>
        item.id === action.payload.id
          ? { ...item, is_enabled: action.payload.is_enabled }
          : item
      );
    },
    changeVesselTypes: (state, action) => {
      state[C.PROPS.VESSEL_FILTER_TYPES] = state[
        C.PROPS.VESSEL_FILTER_TYPES
      ].map(item => ({
        ...item,
        is_enabled: action.payload,
      }));
    },
  },
});

export default slice;
