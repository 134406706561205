import C from './constants';

const isOpen = state => state[C.REDUCER_NAME][C.PROPS.IS_OPEN];

const showTrafficFlow = state =>
  state[C.REDUCER_NAME][C.PROPS.SHOW_TRAFFIC_FLOW];

const showVessels = state => state[C.REDUCER_NAME][C.PROPS.SHOW_VESSELS];

export default {
  isOpen,
  showTrafficFlow,
  showVessels,
};
