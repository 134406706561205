import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Media from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import U from './utils';
import { useSelector } from 'react-redux';
import { selectors as mapSelectors } from '@store/map';
import useAuth from 'src/hooks/useAuth';

const Header = () => {
  const navigate = useNavigate();
  const { logout, getUserToken } = useAuth();
  const token = getUserToken();
  const [credits, setCredits] = useState(null);
  const mapSettings = useSelector(mapSelectors.getMapSettings);

  const handleLogout = async () => {
    try {
      navigate('/login', { replace: true });
      logout();
    } catch (error) {
      console.error(error);
    }
  };

  const getCredits = async () => {
    try {
      const response = await axios.get(
        'https://msa.marinetraffic.com/api/credits'
      );
      setCredits(response.data.identity);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (mapSettings) {
      setCredits(mapSettings.identity);
    } else {
      getCredits();
    }
  }, [mapSettings]);

  if (!credits) return null;

  return (
    <AppBar
      enableColorOnDark
      color="default"
      elevation={3}
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ fontWeight: 'fontWeightMedium' }}
          color="inherit"
        >
          {credits.title}
        </Typography>
        <Stack
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 0.5, sm: 2 }}
          sx={{ ml: 'auto' }}
        >
          {U.getCredits(credits).map(({ id, href, src, sx }) => (
            <Typography
              key={id}
              component="a"
              display="block"
              href={href}
              target="_blank"
              rel="nofollow"
            >
              <Media component="img" alt={id} src={src} sx={sx} />
            </Typography>
          ))}
          {token && (
            <IconButton aria-label="logout" onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
