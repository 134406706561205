import C from './constants';

const getIsLoadingStatus = state =>
  state[C.REDUCER_NAME][C.PROPS.LOADING_SCREEN]?.isLoading;

const getIsLoadingMessage = state =>
  state[C.REDUCER_NAME][C.PROPS.LOADING_SCREEN]?.message;

const getIsLegendOpen = state => state[C.REDUCER_NAME][C.PROPS.LEGEND]?.isOpen;

const getLegendTitle = state => state[C.REDUCER_NAME][C.PROPS.LEGEND]?.title;

const getLegendColors = state => state[C.REDUCER_NAME][C.PROPS.LEGEND]?.colors;

const getLegendCategories = state =>
  state[C.REDUCER_NAME][C.PROPS.LEGEND]?.categories;

export default {
  getIsLoadingStatus,
  getIsLoadingMessage,
  getIsLegendOpen,
  getLegendTitle,
  getLegendColors,
  getLegendCategories,
};
