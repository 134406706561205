import { createSlice } from '@reduxjs/toolkit';
import C from './constants';

const initialState = {
  [C.PROPS.IS_OPEN]: false,
  [C.PROPS.STATUS]: null,
  [C.PROPS.FOCUSED_POINT]: null,
  [C.PROPS.FOCUSED_WKT]: null,
  [C.PROPS.FEATURES]: [
    { id: 'avg_speed', label: 'Average Speed', is_enabled: true },
    { id: 'avg_course', label: 'Average Course', is_enabled: false },
    { id: 'dist_trips', label: 'Dist Trips', is_enabled: false },
  ],
  [C.PROPS.SHIPTYPES]: [
    { id: '*', is_enabled: true },
    { id: '2', is_enabled: false },
    { id: '30', is_enabled: false },
    { id: '31', is_enabled: false },
    { id: '32', is_enabled: false },
    { id: '33', is_enabled: false },
    { id: '34', is_enabled: false },
    { id: '35', is_enabled: false },
    { id: '36', is_enabled: false },
    { id: '37', is_enabled: false },
    { id: '38', is_enabled: false },
    { id: '39', is_enabled: false },
    { id: '4', is_enabled: false },
    { id: '50', is_enabled: false },
    { id: '51', is_enabled: false },
    { id: '52', is_enabled: false },
    { id: '53', is_enabled: false },
    { id: '54', is_enabled: false },
    { id: '55', is_enabled: false },
    { id: '56', is_enabled: false },
    { id: '57', is_enabled: false },
    { id: '58', is_enabled: false },
    { id: '59', is_enabled: false },
    { id: '6', is_enabled: false },
    { id: '7', is_enabled: false },
    { id: '8', is_enabled: false },
    { id: '1', is_enabled: false },
    { id: '9', is_enabled: false },
  ],
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    change: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    changeFeature: (state, action) => {
      state[C.PROPS.FEATURES] = state[C.PROPS.FEATURES].map(item =>
        item.id === action.payload.id
          ? { ...item, is_enabled: action.payload.is_enabled }
          : { ...item, is_enabled: false }
      );
    },
    changeShiptype: (state, action) => {
      state[C.PROPS.SHIPTYPES] = state[C.PROPS.SHIPTYPES].map(item =>
        item.id === action.payload.id
          ? { ...item, is_enabled: action.payload.is_enabled }
          : { ...item, is_enabled: false }
      );
    },
    clearData: (state, action) => {
      state[C.PROPS.DATA] = [];
    },
  },
});

export default slice;
