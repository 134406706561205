import { createSlice } from '@reduxjs/toolkit';
import thunk from './thunk';
import C from './constants';

const initialState = {
  [C.PROPS.IS_READY]: false,
  [C.PROPS.TRACK_SLIDER]: null,
  [C.PROPS.VESSEL_DATA]: [],
  [C.PROPS.VESSEL_DATA_UPDATED_UNIX_TIMESTAMP]: 0,
  [C.PROPS.SHOW_AREA]: false,
  [C.PROPS.SETTINGS]: null,
  [C.PROPS.IS_TRACK_ON]: false,
  [C.PROPS.IS_FORECAST_ON]: false,
  [C.PROPS.IS_POL_ON]: false,
  [C.PROPS.AUTOMATIC_ACTIONS]: [],
  [C.PROPS.MODELS]: [],
  [C.PROPS.TERMS]: [
    { id: 'short', is_enabled: true },
    { id: 'long', is_enabled: false },
  ],
  [C.PROPS.DOA_SENSOR]: [],
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    change: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    changeMapView: (state, action) => {
      state[C.PROPS.SETTINGS][C.PROPS.MAP][C.PROPS.VIEW] = action.payload;
    },
    changeTerm: (state, action) => {
      state[C.PROPS.TERMS] = state[C.PROPS.TERMS].map(item =>
        item.id === action.payload.id
          ? { ...item, is_enabled: action.payload.is_enabled }
          : { ...item, is_enabled: false }
      );
    },
    changeDoaSensor: (state, action) => {
      state[C.PROPS.DOA_SENSOR] = state[C.PROPS.DOA_SENSOR].concat(
        action.payload
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(thunk.getSettings.fulfilled, (state, action) => {
      state[C.PROPS.SETTINGS] = action.payload;
      state[C.PROPS.AUTOMATIC_ACTIONS] = action.payload.automatic_actions;
      state[C.PROPS.MODELS] = action.payload.next_position_models.models.map(
        model => {
          //assign zero value for intervals array
          return {
            ...model,
            intervals: [C.INITIAL_INTERVAL_VALUE, ...model.intervals],
          };
        }
      );
    });
  },
});

export default slice;
