import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import CardMedia from '@mui/material/CardMedia';
import useAuth from '../../hooks/useAuth';
import Layout from '../../components/layout';
import C from './constants';

const Login = () => {
  const { isLoading, login, error } = useAuth();

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const payload = {
      email: data.get('email'),
      password: data.get('password'),
    };
    login(payload);
  };

  return (
    <Layout
      sx={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CardMedia
        image="https://www.marinetraffic.com/research/wp-content/uploads/2021/04/MT_back.png"
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          opacity: 0.5,
        }}
      />
      <Container component="main" maxWidth="xs" sx={{ position: 'relative' }}>
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error">{error}</Alert>
        </Snackbar>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            {C.FIELDS.map(field => (
              <TextField
                key={field.id}
                margin="normal"
                required
                fullWidth
                {...field}
              />
            ))}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              size="large"
              startIcon={
                isLoading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : null
              }
              sx={{ my: 2 }}
            >
              Login
            </Button>
          </Box>
        </Paper>
      </Container>
    </Layout>
  );
};

export default Login;
