import slice from './slice';
import C from './constants';

const { changeIsOpen, setEventTypes, changeEventType, changeEventTypes } =
  slice.actions;

const setMapEventTypes = value =>
  setEventTypes({ key: C.PROPS.MAP_EVENT_TYPES, value });

const changeMapEventType = value =>
  changeEventType({ key: C.PROPS.MAP_EVENT_TYPES, value });

const changeMapEventTypes = value =>
  changeEventTypes({ key: C.PROPS.MAP_EVENT_TYPES, value });

const changePolEventType = value =>
  changeEventType({ key: C.PROPS.POL_EVENT_TYPES, value });

const changePolEventTypes = value =>
  changeEventTypes({ key: C.PROPS.POL_EVENT_TYPES, value });

export default {
  changeIsOpen,
  setMapEventTypes,
  changeMapEventType,
  changeMapEventTypes,
  changePolEventType,
  changePolEventTypes,
};
