const REDUCER_NAME = 'filters';

const PROPS = {
  VESSEL_FILTER_TYPES: 'vesselFilterTypes',
  IS_OPEN: 'isOpen',
};

export default {
  REDUCER_NAME,
  PROPS,
};
