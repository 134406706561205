const REDUCER_NAME = 'vessel';
const PROPS = {
  DATA: 'data',
  RECEIVED_DATA_TIMESTAMP: 'receivedDataTimestamp',
  FOCUSED_VESSEL_MMSI: 'focusedVesselMmsi',
  FOCUSED_SEVERITIES: 'focusedSeverities'
};

export default {
  REDUCER_NAME,
  PROPS,
};
