const styleOverrides = {
  html: {
    height: '100%',
  },
  body: {
    height: '100%',
  },
  '#root': {
    height: '100%',
  },
  a: {
    textDecoration: 'none',
  },
};

export default {
  styleOverrides,
};
