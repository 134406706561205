const SESSION_STORAGE_KEY = 'auth_provider_token';

const API_URL = 'https://msa.marinetraffic.com/api';

const API_MT_CORE_URL = 'https://services.marinetraffic.com/api'

const API_MT_CORE_EXPECTED_ARRIVALS_KEY = `70f1e936a8f8b5456661c07c978bb75c2b7e0cca`

const RADIUS = 120000;

const AREA_ID = '68f20388-b037-4486-b098-09121bab669d';

const EVENT_TIME_INTERVAL = 6 * 60 * 60 * 1000;

export default {
  API_URL,
  API_MT_CORE_URL,
  API_MT_CORE_EXPECTED_ARRIVALS_KEY,
  RADIUS,
  SESSION_STORAGE_KEY,
  EVENT_TIME_INTERVAL,
  AREA_ID,
};
