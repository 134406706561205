import slice from './slice';
import C from './constants';

const { change } = slice.actions;

const changeIsOpen = value => change({ key: C.PROPS.IS_OPEN, value });

const changeShowTrafficFlow = value =>
  change({ key: C.PROPS.SHOW_TRAFFIC_FLOW, value });

const changeShowVessels = value => change({ key: C.PROPS.SHOW_VESSELS, value });

export default {
  changeIsOpen,
  changeShowTrafficFlow,
  changeShowVessels,
};
