import C from './constants';

const getData = state => state[C.REDUCER_NAME][C.PROPS.DATA];

const getReceivedDataTimestamp = state =>
  state[C.REDUCER_NAME][C.PROPS.RECEIVED_DATA_TIMESTAMP];

const getFocusedVesselMMSI = state =>
  state[C.REDUCER_NAME][[C.PROPS.FOCUSED_VESSEL_MMSI]];

const getFocusedSeverities = state =>
  state[C.REDUCER_NAME][C.PROPS.FOCUSED_SEVERITIES];

export default {
  getData,
  getReceivedDataTimestamp,
  getFocusedVesselMMSI,
  getFocusedSeverities,
};
