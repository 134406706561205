import C from './constants';

const isOpen = state => state[C.REDUCER_NAME][C.PROPS.IS_OPEN];

const getMapEventTypes = state => state[C.REDUCER_NAME][C.PROPS.MAP_EVENT_TYPES];

const getPolEventTypes = state => state[C.REDUCER_NAME][C.PROPS.POL_EVENT_TYPES];

export default {
  isOpen,
  getMapEventTypes,
  getPolEventTypes,
};
