import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import AuthContext from './AuthContext';
import C from './constants';

const AuthProvider = props => {
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const token = searchParams.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (token) {
      window.sessionStorage.setItem(C.SESSION_STORAGE_KEY, token);
      navigate('/', { replace: true });
    }
  }, [token, navigate]);

  const login = useCallback(
    payload => {
      setIsLoading(true);
      fetch(C.LOGIN_URL, {
        method: 'POST',
        headers: C.JSON_HEADERS,
        body: JSON.stringify(payload),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          setIsLoading(false);
          window.sessionStorage.setItem(C.SESSION_STORAGE_KEY, data.jwt);
          navigate(from, { replace: true });
        })
        .catch(error => {
          setIsLoading(false);
          setError(error.message);
        });
    },
    [from, navigate]
  );

  const logout = useCallback(() => {
    window.sessionStorage.removeItem(C.SESSION_STORAGE_KEY);
  }, []);

  const getUserToken = useCallback(() => {
    return window.sessionStorage.getItem(C.SESSION_STORAGE_KEY);
  }, []);

  const value = useMemo(
    () => ({ login, logout, getUserToken, isLoading, error }),
    [login, logout, getUserToken, isLoading, error]
  );

  return <AuthContext.Provider value={value} {...props} />;
};

export default AuthProvider;
