import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const SimpleSnackbar = ({ message, severity, open, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert sx={{ width: '100%' }} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SimpleSnackbar;
