import C from './constants';
import slice from './slice';

const { change, changeFocusedVesselMMSI, clearFocusedVesselMMSI } = slice.actions;

const changeFocusedSeverities = value =>
  change({ key: C.PROPS.FOCUSED_SEVERITIES, value });

export default {
  changeFocusedVesselMMSI,
  clearFocusedVesselMMSI,
  changeFocusedSeverities
};
