import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProfile } from '@lib/xhr';

const getRiskProfile = createAsyncThunk(
  'riskProfile/getRiskProfile',
  async (mmsi) => {
    const response = await getProfile(mmsi);
    return JSON.parse(atob(response.RiskDescribedByDocumentRel[0]["Content"]));
  }
);

export default { getRiskProfile };
