import axios from 'axios';
import settings from '@config/settings';
import store from '@store';
import { actions as polActions } from '@store/patternsOfLife';
import { actions as profileActions } from '@store/riskProfile';

const headers = () => ({
  Authorization: `Bearer ${window.sessionStorage.getItem(
    settings.SESSION_STORAGE_KEY
  )}`,
  'Content-Type': 'application/json',
});

export const get = term =>
  axios.get(`${settings.API_URL}${term}`, {
    headers: headers(),
  });

export const post = (term, body) =>
  fetch(`${settings.API_URL}${term}`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(body),
  });

export const getPOL = async ({ point, wktPolygon = null }) => {
  const response = await fetch(`${settings.API_URL}/pol/promenade/`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify({
      type: 'Location',
      Geometry: [
        {
          Latitude: point.lat,
          Longitude: point.lng,
          WKT: wktPolygon,
        },
      ],
    }),
  });
  store.dispatch(polActions.changeStatus(response.status));
  return response.json();
};

export const getProfile = async mmsi => {
  const response = await fetch(`${settings.API_URL}/risk/promenade`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify({
      type: 'Vessel',
      MMSI: mmsi,
    }),
  });
  const resultJSON = await response.text();
  store.dispatch(profileActions.changeStatus(response.status));
  return JSON.parse(resultJSON);
};
