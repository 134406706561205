import slice from './slice';
import C from './constants';

const { change, changePastTrackdata, clearPastTrackData } = slice.actions;

const changeIsPlayerOn = value => change({ key: C.PROPS.IS_PLAYER_ON, value });

const changeIsPlaying = value => change({ key: C.PROPS.IS_PLAYING, value });

const changeSpeed = value => change({ key: C.PROPS.SPEED, value });

const changeResetTrackProgress = value =>
  change({ key: C.PROPS.RESET_TRACK_PROGRESS, value });

const setDateRange = value => change({ key: C.PROPS.DATE_RANGE, value });

export default {
  changeIsPlayerOn,
  changeIsPlaying,
  changeSpeed,
  changeResetTrackProgress,
  setDateRange,
  changePastTrackdata,
  clearPastTrackData,
};
