const REDUCER_NAME = 'eventsList';

const PROPS = {
  MAP_EVENT_TYPES: 'MapEventTypes',
  POL_EVENT_TYPES: 'PolEventTypes',
  IS_OPEN: 'isOpen',
};

const PATTERNS_EVENTS = [
  {
    id: 'spatial',
    label: 'Spatial',
    color: '#04918b',
    is_enabled: false
  },
  {
    id: 'speed',
    label: 'Speed',
    color: '#6281c4',
    is_enabled: false
  },
  {
    id: 'course',
    label: 'Course',
    color: '#afe08d',
    is_enabled: false
  }
];

export default {
  REDUCER_NAME,
  PROPS,
  PATTERNS_EVENTS,
};
