import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@lib/xhr';

const getExpectedArrivals = createAsyncThunk(
  'port/getExpectedArrivals',
  async portid => {
    const response = await get(
      `/expected_arrivals?portid=${portid}&timespan=2`
    );
    //filter arrivals and don't show < expected from now
    return response.data.filter(
      arrival =>
        new Date(arrival.ETA ?? arrival.ETA_UPDATED ?? arrival.ETA_CALC) >
        new Date(Date.now())
    );
  }
);

export default {
  getExpectedArrivals,
};
