import { createSlice } from '@reduxjs/toolkit';
import C from './constants';

const initialState = {
  [C.PROPS.IS_OPEN]: false,
  [C.PROPS.SHOW_TRAFFIC_FLOW]: false,
  [C.PROPS.SHOW_VESSELS]: true,
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    change: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export default slice;
