import map from './map';
import filters from './filters';
import vessel from './vessel';
import nextPosition from './nextPosition';
import eventsList from './eventsList';
import patternsOfLife from './patternsOfLife';
import riskProfile from './riskProfile';
import port from './port';
import player from './player';
import settings from './settings';
import uiApp from './uiApp';

export default {
  map,
  filters,
  vessel,
  nextPosition,
  eventsList,
  patternsOfLife,
  riskProfile,
  port,
  player,
  settings,
  uiApp,
};
