import C from './constants';

const getIsOpen = state => state[C.REDUCER_NAME][C.PROPS.IS_OPEN];

const getStatus = state => state[C.REDUCER_NAME][C.PROPS.STATUS];

const getVesselMMSI = state =>
  state[C.REDUCER_NAME][[C.PROPS.VESSEL_MMSI]];

const getRiskProfile = state => state[C.REDUCER_NAME][C.PROPS.RISK_PROFILE];

export default {
  getIsOpen,
  getVesselMMSI,
  getRiskProfile,
  getStatus,
};
