const REDUCER_NAME = 'port';
const PROPS = {
  IS_ARRIVALS_OPEN: 'isArrivalsOpen',
  ARRIVALS: 'arrivals',
  FOCUSED_PORT: 'focusedPort',
  FOCUSED_VESSEL_ARRIVAL: 'focusedVesselArrival',
  IS_DESTINATION_ON: 'isDestinationOn',
  TYPE: 'type',
};

export default {
  REDUCER_NAME,
  PROPS,
};
