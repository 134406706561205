import settings from '@config/settings';

const LOGIN_URL = `${settings.API_URL}/login`;

const JSON_HEADERS = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods':
    'GET, POST, PUT, DELETE, PATCH, OPTIONS, TRACE, CONNECT',
  'Access-Control-Allow-Headers': 'content-type, Authorization',
};

const SESSION_STORAGE_KEY = settings.SESSION_STORAGE_KEY;

export default {
  LOGIN_URL,
  JSON_HEADERS,
  SESSION_STORAGE_KEY,
};
