const FIELDS = [
  {
    id: 'email',
    type: 'email',
    name: 'email',
    label: 'Email Address',
    autoComplete: 'email',
    autoFocus: true,
  },
  {
    id: 'password',
    type: 'password',
    name: 'password',
    label: 'Password',
    autoComplete: 'current-password',
  },
];

export default {
  FIELDS,
};
