const REDUCER_NAME = 'map';
const PROPS = {
  IS_READY: 'isReady',
  TRACK_SLIDER: 'trackSlider',
  VESSEL_DATA: 'vesselData',
  VESSEL_DATA_UPDATED_UNIX_TIMESTAMP: 'vesselDataUpdatedUnixTimestamp',
  SHOW_AREA: 'showArea',
  SETTINGS: 'settings',
  IS_TRACK_ON: 'isTrackOn',
  IS_FORECAST_ON: 'isForecastOn',
  IS_POL_ON: 'isPolOn',
  AUTOMATIC_ACTIONS: 'automaticActions',
  MODELS: 'models',
  TERMS: 'terms',
  MAP: 'map',
  VIEW: 'view',
  DOA_SENSOR: 'doaSensor',
};

const INITIAL_INTERVAL_VALUE = 0;

export default {
  REDUCER_NAME,
  INITIAL_INTERVAL_VALUE,
  PROPS,
};
