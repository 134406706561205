import slice from './slice';
import C from './constants';

const { change, changeMapView, changeTerm, changeDoaSensor } = slice.actions;

const changeIsReady = value => change({ key: C.PROPS.IS_READY, value });
const changeTrackSlider = value => change({ key: C.PROPS.TRACK_SLIDER, value });
const changeMapSettings = value => change({ key: C.PROPS.SETTINGS, value });
const changeShowArea = value => change({ key: C.PROPS.SHOW_AREA, value });
const changeIsTrackOn = value => change({ key: C.PROPS.IS_TRACK_ON, value });
const changeIsForecastOn = value =>
  change({ key: C.PROPS.IS_FORECAST_ON, value });

const changeIsPatternsOfLifeOn = value =>
  change({ key: C.PROPS.IS_POL_ON, value });

export default {
  changeMapSettings,
  changeTrackSlider,
  changeMapView,
  changeTerm,
  changeIsReady,
  changeShowArea,
  changeIsTrackOn,
  changeIsForecastOn,
  changeIsPatternsOfLifeOn,
  changeDoaSensor,
};
