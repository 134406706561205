const REDUCER_NAME = 'riskProfile';

const PROPS = {
  RISK_PROFILE: 'riskProfile',
  IS_OPEN: 'isOpen',
  STATUS: 'status',
  VESSEL_MMSI: 'vesselMmsi'
};

export default {
  REDUCER_NAME,
  PROPS,
};
