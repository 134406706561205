import C from './constants';

const getIsPlayerOn = state => state[C.REDUCER_NAME][C.PROPS.IS_PLAYER_ON];

const getIsPlaying = state => state[C.REDUCER_NAME][C.PROPS.IS_PLAYING];

const getSpeed = state => state[C.REDUCER_NAME][C.PROPS.SPEED];

const getResetTrackProgress = state =>
  state[C.REDUCER_NAME][C.PROPS.RESET_TRACK_PROGRESS];

const getDateRange = state => state[C.REDUCER_NAME][C.PROPS.DATE_RANGE];

const getPastTrackData = state => state[C.REDUCER_NAME][C.PROPS.DATA];

export default {
  getIsPlayerOn,
  getIsPlaying,
  getSpeed,
  getResetTrackProgress,
  getDateRange,
  getPastTrackData,
};
