import C from './constants';
import slice from './slice';

const { change } = slice.actions;

const changeDestinationType = value => change({ key: C.PROPS.TYPE, value });

const changeIsArrivalsOpen = value =>
  change({ key: C.PROPS.IS_ARRIVALS_OPEN, value });

const changeIsDestinationOn = value =>
  change({ key: C.PROPS.IS_DESTINATION_ON, value });

const changeFocusedPort = value => change({ key: C.PROPS.FOCUSED_PORT, value });

const changeFocusedVesselArrival = value =>
  change({ key: C.PROPS.FOCUSED_VESSEL_ARRIVAL, value });

export default {
  changeDestinationType,
  changeFocusedPort,
  changeIsArrivalsOpen,
  changeFocusedVesselArrival,
  changeIsDestinationOn,
};
