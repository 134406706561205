import slice from './slice';

const {
  changeLoadingStatus,
  changeLoadingMessage,
  changeIsLegendOpen,
  changeLegendTitle,
  changeLegendColors,
  changeLegendCategories,
} = slice.actions;

export default {
  changeLoadingStatus,
  changeLoadingMessage,
  changeIsLegendOpen,
  changeLegendTitle,
  changeLegendColors,
  changeLegendCategories,
};
