const REDUCER_NAME = 'patternsOfLife';
const PROPS = {
  IS_OPEN: 'isOpen',
  STATUS: 'status',
  FOCUSED_POINT: 'focusedPoint',
  FOCUSED_WKT: 'focusedWkt',
  FEATURES: 'features',
  SHIPTYPES: 'shiptypes',
};

export default {
  REDUCER_NAME,
  PROPS,
};
