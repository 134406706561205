import mtLogo from 'src/assets/marinetraffic_signet_blue.png';

const getCredits = credits => {
  if (!credits) return null;
  return [
    {
      id: 'marinetraffic',
      href: 'https://www.marinetraffic.com',
      src: mtLogo,
      sx: { width: 25 },
    },
    {
      ...credits,
      sx: { width: 100, filter: 'grayscale(1) invert(1)' },
    },
  ];
};

export default {
  getCredits,
};
