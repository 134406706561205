import C from './constants';

const getIsReady = state => state[C.REDUCER_NAME][C.PROPS.IS_READY];

const getTrackSlider = state => state[C.REDUCER_NAME][C.PROPS.TRACK_SLIDER];

const getStatus = state => state[C.REDUCER_NAME][C.PROPS.STATUS];

const getShowArea = state => state[C.REDUCER_NAME][C.PROPS.SHOW_AREA];

const getMapSettings = state => state[C.REDUCER_NAME][C.PROPS.SETTINGS];

const getMapEvents = state => state[C.REDUCER_NAME][C.PROPS.SETTINGS]?.events;

const getMapAutomaticActions = state =>
  state[C.REDUCER_NAME][C.PROPS.AUTOMATIC_ACTIONS];

const getModels = state => state[C.REDUCER_NAME][C.PROPS.MODELS];

const getIsTrackOn = state => state[C.REDUCER_NAME][[C.PROPS.IS_TRACK_ON]];

const getIsForecastOn = state =>
  state[C.REDUCER_NAME][[C.PROPS.IS_FORECAST_ON]];

const getIsPatternsOfLifeOn = state => state[C.REDUCER_NAME][C.PROPS.IS_POL_ON];

const getTerms = state => state[C.REDUCER_NAME][C.PROPS.TERMS];

const getDoaSensor = state => state[C.REDUCER_NAME][C.PROPS.DOA_SENSOR];

export default {
  getIsReady,
  getTrackSlider,
  getShowArea,
  getMapSettings,
  getIsTrackOn,
  getMapEvents,
  getMapAutomaticActions,
  getIsForecastOn,
  getStatus,
  getModels,
  getTerms,
  getIsPatternsOfLifeOn,
  getDoaSensor,
};
