import C from './constants';

const getVesselFilterTypes = state =>
  state[C.REDUCER_NAME][C.PROPS.VESSEL_FILTER_TYPES];

const isOpen = state => state[C.REDUCER_NAME][C.PROPS.IS_OPEN];

export default {
  getVesselFilterTypes,
  isOpen,
};
