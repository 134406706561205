const defaultProps = {
  disableElevation: true,
};

const styleOverrides = {
  sizeLarge: {
    height: 48,
  },
  sizeMedium: {
    height: 40,
  },
  sizeSmall: {
    height: 32,
  },
};

export default {
  defaultProps,
  styleOverrides,
};
