import slice from './slice';
import C from './constants';

const { change } = slice.actions;

const changeIsOpen = value => change({ key: C.PROPS.IS_OPEN, value });

const changeVesselMmsi = value => change({ key: C.PROPS.VESSEL_MMSI, value });

const changeStatus = value => change({ key: C.PROPS.STATUS, value });

export default {
  changeIsOpen,
  changeVesselMmsi,
  changeStatus,
};
