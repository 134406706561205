const REDUCER_NAME = 'player';

const DEFAULT_TRACK_SPEED = 4;

const NOW = new Date();

const NOW_CONVERTED = NOW.getTime() - NOW.getTimezoneOffset() * 60000;

const DEFAULT_START_DATE = NOW_CONVERTED - 60 * 60 * 1000 * 6; //6 hours ago

const DEFAULT_END_DATE = NOW_CONVERTED; //now in UTC

const PROPS = {
  IS_PLAYER_ON: 'isPlayerOn',
  IS_PLAYING: 'isPlaying',
  SPEED: 'speed',
  RESET_TRACK_PROGRESS: 'resetTrackProgress',
  DATE_RANGE: 'dateRange',
  DATA: 'data',
};

export default {
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
  DEFAULT_TRACK_SPEED,
  REDUCER_NAME,
  PROPS,
};
