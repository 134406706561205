import React, { lazy, Suspense } from 'react';

import NoMatch from './components/noMatch';
import RequireAuth from './components/requireAuth';
import Login from './components/login';

const VesselAI = lazy(() => import('./projects/vesselAI'));

const routes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: (
      <RequireAuth>
        <Suspense fallback={<div />}>
          <VesselAI />
        </Suspense>
      </RequireAuth>
    ),
  },
  {
    path: '*',
    element: <NoMatch />,
  },
];

export default routes;
