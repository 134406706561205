import React from 'react';
import Box from '@mui/material/Box';
import Header from './Header';

const Layout = ({ children, ...other }) => {
  return (
    <Box {...other}>
      <Header />
      {children}
    </Box>
  );
};

export default Layout;
