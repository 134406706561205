import { useRef, useState, useMemo, useCallback } from 'react';
import SnackbarContext from './SnackbarContext';
import SimpleSnackbar from './SimpleSnackbar';

const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(undefined);
  const queueRef = useRef([]);

  const closeSnackbarCallback = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }, []);

  const openSnackbarCallback = useCallback(
    message => {
      queueRef.current.push(message);

      if (open) {
        setOpen(false);
      } else {
        const processQueue = () => {
          if (queueRef.current.length > 0) {
            if (queueRef.current.length > 0) {
              setMessage(queueRef.current.shift());
              setOpen(true);
            }
          }
        };

        processQueue();
      }
    },
    [open]
  );

  const value = useMemo(
    () => ({
      openSnackbar: openSnackbarCallback,
      closeSnackbar: closeSnackbarCallback,
    }),
    [openSnackbarCallback, closeSnackbarCallback]
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <SimpleSnackbar
        {...message}
        open={open}
        handleClose={closeSnackbarCallback}
      />
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
