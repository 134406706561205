import { createSlice } from '@reduxjs/toolkit';
import thunk from './thunk';
import C from './constants';

const initialState = {
  [C.PROPS.IS_OPEN]: false,
  [C.PROPS.VESSEL_MMSI]: null,
  [C.PROPS.RISK_PROFILE]: null,
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    change: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: builder => {
    builder.addCase(thunk.getRiskProfile.fulfilled, (state, action) => {
      state[C.PROPS.RISK_PROFILE] = action.payload;
    });
    builder.addCase(thunk.getRiskProfile.rejected, (state, action) => {
      state[C.PROPS.RISK_PROFILE] = null;
      state[C.PROPS.IS_OPEN] = false;
      state[C.PROPS.VESSEL_MMSI] = null;
    });
  },
});

export default slice;
