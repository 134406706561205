import { createSlice } from '@reduxjs/toolkit';
import C from './constants';
import thunk from './thunk';

const initialState = {
  [C.PROPS.DATA]: [],
  [C.PROPS.RECEIVED_DATA_TIMESTAMP]: 0,
  [C.PROPS.FOCUSED_VESSEL_MMSI]: [],
  [C.PROPS.FOCUSED_SEVERITIES]: ['Low', 'Medium', 'High'],
};

const slice = createSlice({
  name: C.REDUCER_NAME,
  initialState,
  reducers: {
    change: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    changeFocusedVesselMMSI: (state, action) => {
      state[C.PROPS.FOCUSED_VESSEL_MMSI] = state[
        C.PROPS.FOCUSED_VESSEL_MMSI
      ].includes(action.payload)
        ? state[C.PROPS.FOCUSED_VESSEL_MMSI]
        : state[C.PROPS.FOCUSED_VESSEL_MMSI].concat(action.payload);
    },
    clearFocusedVesselMMSI: (state, action) => {
      state[C.PROPS.FOCUSED_VESSEL_MMSI] = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(thunk.getVessels.fulfilled, (state, action) => {
      state[C.PROPS.DATA] = action.payload;
      state[C.PROPS.RECEIVED_DATA_TIMESTAMP] = Date.now() + 10000;
    });
  },
});

export default slice;
