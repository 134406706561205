const REDUCER_NAME = 'uiApp';

const PROPS = {
  LOADING_SCREEN: 'loadingScreen',
  LEGEND: 'legend',
};

const INIT_LOADING = {
  isLoading: false,
  message: 'Loading...',
};

const INIT_LEGEND = {
  isOpen: false,
  title: 'Legend',
  colors: {},
  categories: {},
};

export default {
  REDUCER_NAME,
  INIT_LOADING,
  INIT_LEGEND,
  PROPS,
};
